@font-face {
    font-family: 'Kanit';
    src: url('./../fonts/Kanit/Kanit-Regular.ttf') format('truetype');
}

/* @font-face {
    font-family: 'Roboto';
    src: url('./../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
} */

/* @font-face {
    font-family: 'THSarabunPSK';
    src: url('./../fonts/THSarabunPSK/THSarabunNew.ttf') format('truetype');
} */